import logo from './logo.svg';
import ghost from './ghost.svg';
import tree from './tree.svg';
import emailIcon from './email-icon.svg';
import venmo from './venmo.svg';
import facebook from './facebook.svg';
import instagram from './instagram.svg';

import './app.css';

function App() {

  return (
    <div className="app">
      <header className="app-header">
        <div class="header-link">HOME</div>
        <div class="header-link">EVENTS</div>
        <div class="header-link">PORTFOLIO</div>
        <div class="header-link">SHOP</div>
      </header>

      <div class="page-home">
        <div class="logo-container">
          <img src={logo} class="logo" />
        </div>

        <div class="stuff-container">
          <div>
            <img src={tree} class="tree-left" />
            <img src={ghost} class="ghost-left" />
          </div>

          <div>
            <img src={ghost} class="ghost-right" />
            <img src={tree} class="tree-right" />
          </div>
        </div>

        <div class="welcome-container">
          <h1>SPOOKY FOREST CO.</h1>

          <span>Art & Stories from the Bridgewater Triangle</span>
        </div>

        <div class="content-container">
          <div class="content-body">
            Welcome to my spooky little corner of the forest from my home studio in the Bridgewater Triangle; a region of dense forest and swamplands in Southeastern Massachusetts home to mysterious creatures, haunted forests, and an epicenter for paranormal sightings that inspires my art and stories. Check out my event schedule for where you can find me next!
          </div>
        </div>

        <div class="footer-container">
          <div class="footer-wrapper">
            <div class="footer-email">
            <a href="mailto:SPOOKYFORESTCO@GMAIL.COM"><img src={emailIcon} /></a>

              <div>
                <div><a href="mailto:SPOOKYFORESTCO@GMAIL.COM">SPOOKYFORESTCO@GMAIL.COM</a></div>
                <div>Available for commissions, workshops, and vendor/cons/craft events.</div>
              </div>
            </div>

            <div class="footer-socials">
              <a href="https://venmo.com/aliandspooks"><img src={venmo} /></a>
              <a href="https://www.facebook.com/spookyforestco"><img src={facebook} /></a>
              <a href="https://www.instagram.com/spookyforestco"><img src={instagram} /></a>
            </div>
          </div>
        </div>

        <div class="cw-container">
          ©2022 Spooky Forest Co.
        </div>
      </div>
    </div>
  );
}

export default App;
